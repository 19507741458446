import axios from "./base";

function buildQueryParams(filters) {
    const params = new URLSearchParams();

    if (filters.organization && filters.organization !== 'all') {
        params.append('organization', filters.organization);
    }

    if (filters.fromDate && filters.fromDate !== 'all') {
        params.append('from_date', filters.fromDate);
    }

    if (filters.toDate && filters.toDate !== 'all') {
        params.append('to_date', filters.toDate);
    }

    if (filters.productCategory && filters.productCategory !== 'all') {
        params.append('product_category', filters.productCategory);
    }

    if (filters.inquiryStatus && filters.inquiryStatus !== 'all') {
        params.append('inquiry_status', filters.inquiryStatus);
    }

    return params;
}

export function getNumberOfRequestsOverTime(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/requests-over-time`, {params});
}

export function getNumberOfRequests(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/number-of-requests`, {params});
}

export function getNumberOfOrganizations(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/number-of-organizations`, {params});
}

export function getNumberOfUsers(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/number-of-users`, {params});
}

export function getTotalProfit(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/total-profit`, {params});
}

export function getTopMainCategories(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/top-main-categories`, {params});
}

export function getTopStores(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/top-stores`, {params});
}

export function getRequestProcessingMeanTime(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/request-checking-mean-time`, {params});
}

export function getOrganizations() {
    return axios.get(`user/organizations/`);
}

export function getTotalSpending(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/total-spending/`, {params});
}

export function getSpendingOverTime(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/spending-over-time/`, {params});
}

export function getSpendingDistributionByMainCategory(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/spending-distribution-by-main-category/`, {params});
}

export function getTopOrganizations(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/top-organizations/`, {params});
}

export function getSpendingDistributionByStore(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/spending-distribution-by-store/`, {params});
}

export function getStoreByMainCategoryDistribution(filters, main_category) {
    const params = buildQueryParams(filters);
    if (main_category) {
        params.append('main_category', main_category);
    }

    return axios.get(`dashboard/store-distribution-by-main-category/`, {params});
}

export function getRequestsDistributionByProductMainCategory(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/requests-distribution-by-category/`, {params});
}

export function getMainCategories(filters) {
    const params = buildQueryParams(filters);

    return axios.get(`dashboard/main-categories/`, {params});
}