import styles from "../../styles/auditor/desktop/dashboard/PerformanceSection.module.css";
import common_styles from "../../styles/auditor/desktop/dashboard/common.module.css";
import {IoGridOutline} from "@react-icons/all-files/io5/IoGridOutline";
import ReactLoading from "react-loading";
import CountUp from "react-countup";
import React, {useRef, useState} from "react";
import useNumberOfRequests from "../../hooks/dashboard/overview_section/useNumberOfRequests";
import useRequestProcessingMeanTime
    from "../../hooks/dashboard/overview_section/useRequestProcessingMeanTime";
import {MdShowChart} from "@react-icons/all-files/md/MdShowChart";
import useNumberOfRequestsLineChart
    from "../../hooks/dashboard/overview_section/useNumberOfRequestsLineChart";
import RankingTable from "../../components/RankingTable/RankingTable";
import {FaTable} from "@react-icons/all-files/fa/FaTable";
import useOrganizationsRankingTable
    from "../../hooks/dashboard/financial_section/useOrganizationsRankingTable";
import useRequestDistributionByProductMainCategory
    from "../../hooks/dashboard/performance_section/useRequestDistributionByProductMainCategory";
import {IoPieChartOutline} from "@react-icons/all-files/io5/IoPieChartOutline";
import CreatableSelect from "react-select/creatable";

const PerformanceSection = ({filters}) => {
    // number of requests
    const [numberOfRequestsLoading, setNumberOfRequestsLoading] = useState(false);
    const numberOfRequests = useNumberOfRequests(filters, setNumberOfRequestsLoading);

    // inquiry processing time
    const [requestProcessingMeanTimeLoading, setRequestProcessingMeanTimeLoading] = useState(false);
    const requestProcessingMeanTime = useRequestProcessingMeanTime(filters, setRequestProcessingMeanTimeLoading);

    // number of requests over time chart
    const [numberOfRequestsLineChartLoading, setNumberOfRequestsLineChartLoading] = useState(false);
    const numberOfRequestsLineChartRef = useRef(null);
    const numberOfRequestsLineChartInstanceRef = useRef(null);
    useNumberOfRequestsLineChart(numberOfRequestsLineChartRef, numberOfRequestsLineChartInstanceRef, filters, setNumberOfRequestsLineChartLoading);

    // orgs ranking table
    const [topOrganizationsLoading, setTopOrganizationsLoading] = useState(false);
    const {
        organizationsRankingHeaders,
        organizationsRankingData
    } = useOrganizationsRankingTable(filters, setTopOrganizationsLoading);

    // requests distribution by product main category
    const [requestByProductMainCategoryLoading, setRequestByProductMainCategoryLoading] = useState(false);
    const requestByProductMainCategoryPieChartRef = useRef(null);
    const requestByProductMainCategoryPieInstanceRef = useRef(null);
    useRequestDistributionByProductMainCategory(requestByProductMainCategoryPieChartRef, requestByProductMainCategoryPieInstanceRef, filters, setRequestByProductMainCategoryLoading);

    return (
        <div className={styles.performance_section_container}>
            <div className={styles.performance_section_first_row}>
                <div className={styles.performance_section_first_row_first_col}>
                    <div className={styles.performance_section_first_row_first_col_first_row}>
                        <div className={common_styles.half_size_statistic_container}>
                            <div className={common_styles.section_header_container}>
                                <IoGridOutline className={common_styles.section_icon}/>
                                <span className={common_styles.section_header}>
                                    تعداد کل درخواست‌ها
                                </span>
                            </div>
                            {numberOfRequestsLoading ?
                                <div className={common_styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                                  height='50px'/>
                                </div>
                                :
                                <span className={common_styles.statistic_text}>
                                    <CountUp end={numberOfRequests} duration={5}/>
                                </span>
                            }
                        </div>
                        <div className={common_styles.half_size_statistic_container}>
                            <div className={common_styles.section_header_container}>
                                <IoGridOutline className={common_styles.section_icon}/>
                                <span className={common_styles.section_header}>
                                    میانگین زمان بررسی درخواست‌ها
                                </span>
                            </div>
                            {requestProcessingMeanTimeLoading ?
                                <div className={common_styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                                  height='50px'/>
                                </div>
                                :
                                <span className={common_styles.statistic_text}>
                                    <CountUp end={requestProcessingMeanTime} duration={5}/>
                                    {'  روز'}
                                </span>
                            }
                        </div>
                    </div>
                    <div className={styles.performance_section_first_row_first_col_second_row}>
                        <div className={common_styles.trend_chart}>
                            <div className={common_styles.section_header_container}>
                                <MdShowChart className={common_styles.section_icon}/>
                                <span className={common_styles.section_header}>
                                    نمودار روند تعداد درخواست‌ها در طول زمان
                                </span>
                            </div>
                            {numberOfRequestsLineChartLoading ?
                                <div className={common_styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                                  height='50px'/>
                                </div>
                                :
                                <div className={common_styles.trend_chart_body}>
                                    <canvas ref={numberOfRequestsLineChartRef}></canvas>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.performance_section_second_row}>
                <div className={common_styles.half_size_ranking_table_container}>
                    <div className={common_styles.section_header_container}>
                        <FaTable className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            سازمان‌های برتر
                        </span>
                    </div>
                    {topOrganizationsLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={common_styles.ranking_table}>
                            <RankingTable
                                headers={organizationsRankingHeaders}
                                data={organizationsRankingData}
                                tableTitle={"سازمان‌ها با بیشترین مجموع خرید"}
                            />
                        </div>
                    }
                </div>
                <div className={styles.pie_chart}>
                    <div className={common_styles.section_header_container}>
                        <IoPieChartOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            توزیع میزان خرید براساس گروه کالا
                        </span>
                    </div>
                    {requestByProductMainCategoryLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={styles.pie_chart_body}>
                            <canvas ref={requestByProductMainCategoryPieChartRef}></canvas>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PerformanceSection;